<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">

          <el-form-item
            :label="$t('Office')"
            prop="office"
            :rules="[{ required: true, message: 'The field is required'}]"
          >
            <el-select
              v-model="form.office"
              placeholder="Select Office"
              class="w-100"
              @change="getClientsAndUsers"
            >
              <el-option
                v-for="item in offices"
                :key="item.id"
                :label="item.office_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

           <el-form-item
            :label="$t('Client')"
            prop="client"
          >
            <el-select
              v-model="form.client"
              placeholder="Select Client"
              class="w-100"
            >
              <el-option
                v-for="item in clients"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="$t('Assign to')"
            prop="assignto"
          >
            <el-select
              v-model="form.assignto"
              placeholder="Select User"
              class="w-100"
            >
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          

          <el-form-item
            :label="$t('Task Subject')"
            prop="title"
            :rules="[{ required: true, message: 'The field is required'}]"
          >
            <el-input type="text" v-model="form.title"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('Priority')"
            prop="priority"
            :rules="[{ required: true, message: 'Priority is required'}]"
          >
            <el-select
              v-model="form.priority"
              placeholder="Select Priority"
              class="w-100"
            >
              <el-option value="Low" >Low</el-option>
              <el-option value="Medium" >Medium</el-option>
              <el-option value="High" >High</el-option>
              <el-option value="Urgent" >Urgent</el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="$t('Category')"
            prop="category"
          >
            <el-select
              v-model="form.category"
              placeholder="Select Category"
              class="w-100"
            >
              <el-option value="Pay Roll" >Pay Roll</el-option>
              <el-option value="Licenses" >Licenses</el-option>
              <el-option value="Sales Tax" >Sales Tax</el-option>
              <el-option value="Corp Tax" >Corp Tax</el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="$t('Due Date')"
            prop="duedate"
          >
            <el-date-picker
              type="date"
              v-model="form.duedate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item> 

          <el-form-item
            :label="$t('Note Detail')"
            prop="detail"
            :rules="[{ required: true, message: 'The field is required'}]"
          >
            <el-input type="textarea" v-model="form.detail" :rows="5"></el-input>
          </el-form-item> 

          <el-form-item
            :label="$t('Status')"
            prop="status"
          >
            <el-select
              v-model="form.status"
              placeholder="Select Status"
              class="w-100"
            >
              <el-option value="Pending" >Pending</el-option>
              <el-option value="Done" >Done</el-option>
            </el-select>
          </el-form-item>                

      <el-button type="success" @click="(!data)?submitForm('form'):updateForm('form')">Save Task</el-button>
    </el-form>
  </div>
</template>

<script>
import corporate from "@/services/api/corporate";
import office from "@/services/api/office";
import note from "@/services/api/note";
import user from "@/services/api/user";
import officeUser from "@/services/api/officeUser";

export default {
  props: ["data"],
  data() {
    return {
      title: '',
      due_date: '',
      status: '',
      detail: '',
      assignto: '',
      category: '',
      clients: [],
      offices: [],
      categories: [],
      users: [],
      form: {
        title: null,
        priority: null,
        detail: null,
        client: null,
        office: null,
        status: null,
        assignto: null,
        category: null,
        duedate: null,
        user: this.$store.getters.id
      }
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then(response => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        this.discriminator = "office";
        officeUser.getOffice(this.$store.getters.id).then(response => {
          this.offices = response.map(item => {
            return {
              id: item.office.id,
              office_name: item.office.office_name
            };
          });
        });
        break;
    }
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.title = this.data.title;
      this.form.priority = this.data.priority;
      this.form.detail = this.data.detail;
      this.form.duedate = this.data.duedate;
      this.form.office = this.data.officeId;
      corporate.getClientsByOffice(this.form.office).then(response => {
        this.clients = response;
      });
      this.form.client = this.data.clientId;
      this.form.user = this.data.userId;
      user.getUsersByOffice(this.form.office).then(response => {
        this.users = response;
      });
      this.form.assignto = this.data.assignTo;
      this.form.category = this.data.category;
      this.form.status = this.data.status;      
    }
  },
  methods: {

    getClientsAndUsers(){

      corporate.getClientsByOffice(this.form.office).then(response => {
        this.clients = response;
      });

      user.getUsersByOffice(this.form.office).then(response => {
        this.users = response;
      });

    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          note
            .create(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: false });
              this.$message({
                message: "Task registred...",
                type: "success",
                customClass: "message-success"
              });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          note
            .update(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
